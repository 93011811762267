import { Component, OnInit } from '@angular/core';
import {Chart, registerables} from 'chart.js/auto';

import { CommonModule } from '@angular/common';

Chart.register(...registerables);

@Component({
  selector: 'app-energy-consumption-graph',
  standalone: true,
  imports: [CommonModule],
  template: `
    <h3>Energie Verbruik over 14 Dagen</h3>
    <canvas id="energyConsumptionChart"></canvas>
  `,
  styles: [`
    canvas {
      max-width: 100%;
      max-height: 220px;
    }
  `]
})
export class EnergyConsumptionGraphComponent implements OnInit {
  ngOnInit() {
    this.renderChart();
  }

  renderChart() {
    const ctx = document.getElementById('energyConsumptionChart') as HTMLCanvasElement;

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.getLast14Days(),
        datasets: [{
          label: 'Energie Verbruik (kWh)',
          data: this.getMockData(),
          fill: false,
          borderColor: 'rgba(75, 192, 192, 1)',
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          }
        }
      }
    });
  }

  getLast14Days(): string[] {
    const today = new Date();
    const labels = [];
    for (let i = 13; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      labels.push(date.toLocaleDateString());
    }
    return labels;
  }

  getMockData(): number[] {
    return Array.from({ length: 14 }, () => Math.floor(Math.random() * 100)); // Random data for testing
  }
}
