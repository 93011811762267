import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="user-list-container">
      <h2>Portfolio</h2>
      <br>
      <div class="search-container">
        <input class="searchbar" type="text" placeholder="Zoek naar klant op naam..." [(ngModel)]="searchQuery" />
      </div>

      <div class="table-container">
        <table class="user-table">
          <thead>
            <tr>
              <th>Naam</th>
              <th>Manager</th>
              <th>Gebouwen</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of filterusers(); let i = index" [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}" (click)="goTouserDetails(user.id)">
              <td>{{ user.name }}</td>
              <td>{{ user.manager }}</td>
              <td>{{ user.gebouwen }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <button class="add-user-btn" (click)="adduser()">Voeg een nieuwe klant toe <img src="../../../assets/add.png" alt="add" class="icon"/></button>
    </div>
  `,
  styles: [`
    .user-list-container {
      position: relative; /* Keep positioning relative */
      padding-bottom: 60px; /* Add some bottom padding */
    }

    .search-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .search-container input {
      width: 40%;
      padding: 8px;
      margin-right: 10px;
      border: 1px solid white;
      background-color: #f1f1f1;
      border-radius: 4px;
    }

    .map-button {
      padding: 8px 12px;
      background-color: white;
      color: black;
      border: none;
      cursor: pointer;
        display: flex; /* Use flexbox for alignment */
        align-items: center; /* Center items vertically */
        justify-content: center;
    }

    .table-container {
      max-height: 400px;
      overflow-y: auto;
      margin-bottom: 20px;
    }

    .user-table {
      width: 100%;
      border-collapse: collapse;
    }

    .user-table th {
      border-bottom: 2px solid #ddd;
      font-weight: bold;
      text-align: left;
      padding: 8px;
    }

    .user-table td {
      border: none;
      padding: 15px;
    }

    .user-table tbody tr.even-row {
      background-color: white;
      cursor: pointer;
    }

    .user-table tbody tr.odd-row {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    .user-table tbody tr:hover {
      background-color: #f1f1f1; 
    }

    .add-user-btn {
      margin-top: 10px; /* Space above the button */
      padding: 10px;
      background-color: white;
      color: black;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      z-index: 1000;
      float:right;
        display: flex; /* Use flexbox for alignment */
        align-items: center; /* Center items vertically */
        justify-content: center;
    }

    .add-user-btn .icon{
    height: 20px;
    width: auto;
    margin-left: 8px;
    }

    .map-button .icon {
    height: 30px;
    width: auto;
    margin-left: 8px;
    filter: grayscale(100%) brightness(10%);
    }

    .icon {
      width: 24px; /* Set the width of icons */
      height: auto; /* Maintain aspect ratio */
      margin-right: 5px; /* Space between icons */
    }

    .grayscale {
      filter: grayscale(100%) brightness(140%);
    }
  `]
})
export class UserListComponent {

    constructor(private router: Router) {}

  searchQuery: string = '';

  users = [
    { id: 1, name: 'Union investment', manager: 'Maike Meek', gebouwen: 11 },
    { id: 2, name: 'Stichting primair onderwijs', manager: 'Annekoos Schaap', gebouwen: 23 },
  ];

  filterusers() {
    return this.users.filter(user => 
      user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  viewOnMap() {
  }

  adduser() {
  }

  goTouserDetails(id: number) {
    this.router.navigate(['/user', id]);
  }
}
