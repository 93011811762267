import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AssistantComponent } from '../../components/assistant/assistant.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { NotificationsComponent } from '../../components/notifications/notifications.component';
import { DashboardInformationComponent } from '../../components/dashboard-information/dashboard-information.component';
import { BuildingListComponent } from 'src/app/components/dashboard-information/building-list.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  standalone: true,
  imports: [
    AssistantComponent,
    NavbarComponent,
    NotificationsComponent,
    DashboardInformationComponent,
    BuildingListComponent
  ]
})
export class DashboardComponent {

  constructor(private router: Router) { }
}
