import { Injectable, Inject } from '@angular/core';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { AuthenticationResult, 
  RedirectRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly TOKEN_KEY = 'auth_token';

  constructor(
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
  }

  storeToken(result: AuthenticationResult): void {
    sessionStorage.setItem(this.TOKEN_KEY, result.idToken);
    console.log('Authentication token stored successfully.');
  }

  getToken(): string | null {
    return sessionStorage.getItem(this.TOKEN_KEY);
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }

    const payload = JSON.parse(atob(token.split('.')[1]));
    const now = Math.floor(new Date().getTime() / 1000);

    return now >= payload.exp;
  }

  ensureAuthenticated(): void {
    const token = this.getToken();
    if (!token || this.isTokenExpired()) {
      console.warn('Token is missing or expired. Redirecting to login.');
      this.loginRedirect();
    }
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logoutRedirect():void{
    this.authService.logoutRedirect();
  }

  handleLoginRedirect(): void {
    this.authService.handleRedirectObservable().subscribe((result) => {
      if (result) {
        this.storeToken(result);
      }
    });
  }
}
