<div class="assistant-container">
  <div class="center-logo">
    <img class="logo" src="assets/Logo.png" alt="Logo" />
  </div>
  <p class="co-operator-header">Co-Operator Assistant</p>

  <div class="chat-box">
    <div
      *ngFor="let msg of messages"
      class="chat-content"
      [class.user]="msg.sender === 'user'"
      [class.ai]="msg.sender === 'ai'"
    >
      <img
        *ngIf="msg.sender === 'ai'"
        class="logo-pic"
        src="assets/small_logo.png"
        alt="AI Profile Picture"
      />
      <p class="chat" [innerHTML]="formatMessageContent(msg.content)"></p>
    </div>

    <div *ngIf="isLoading" class="chat-content ai">
      <img class="logo-pic" src="assets/small_logo.png" alt="AI Profile Picture" />
      <div class="bouncing-dots">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
      </div>
    </div>
  </div>

  <div class="input-container">
    <input
      type="text"
      [(ngModel)]="userMessage"
      placeholder="Chat met de Entune assistent en krijg nieuwe inzichten."
      [disabled]="isLoading" 
      (keydown.enter)="sendMessage()"
      />
    <img class="send-icon" src="assets/send.png" alt="Send" (click)="sendMessage()" />
  </div>

  <div class="user-profile">
    <img class="profile-pic" src="assets/person_logo.jpg" alt="Profile Picture" />
    <div class="user-info">
      <p class="user-name">{{user.fullName}}</p>
      <p class="user-role">{{user.userRole}}</p>
    </div>
    <img
      class="user-settings"
      mat-raised-button
      [matMenuTriggerFor]="logoutMenu"
      src="assets/settings.png"
      alt="settings"
    />
    <mat-menu #logoutMenu="matMenu">
      <button mat-menu-item (click)="logout()">Logout</button>
      <button mat-menu-item (click)="clearChat()">Clear chat</button>
    </mat-menu>
  </div>
</div>
