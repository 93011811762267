import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface Notification {
  id: number;
  reason: string;
  date: Date;
  buildingName: string;
  type: string; 
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor() {}

  getNotifications(): Observable<Notification[]> {
    const fakeNotifications: Notification[] = [
      { id: 1, reason: 'Scheduled maintenance', date: new Date(), buildingName: 'Building A', type: 'Alert' },
      { id: 2, reason: 'New update available', date: new Date(), buildingName: 'Building B', type: 'Idea' },
      { id: 3, reason: 'You have a new building', date: new Date(), buildingName: 'Building C', type: 'Information' },
      { id: 4, reason: 'Scheduled maintenance on HVAC system', date: new Date(), buildingName: 'Building D', type: 'Alert' },
      { id: 5, reason: 'Fire drill scheduled for tomorrow', date: new Date(), buildingName: 'Building E', type: 'Information' },
    ];

    return of(fakeNotifications);
  }
}
