import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BuildingService {
  response: any 
  url: string = 'https://coop-aks.swedencentral.cloudapp.azure.com/buildingservice';
  data: any

  constructor(private http: HttpClient) {
  }

  private getAuthToken(): string | null {
    return sessionStorage.getItem('auth_token');
  }

  async getAllBuildings(): Promise<any> {
    console.log("Getting all buildings");

    try {
      const token = this.getAuthToken();

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const url = `${this.url}/api/Building`

      return lastValueFrom(this.http.get(url, { headers: headers }));
    } catch (error) {
      console.error("Error fetching building data:", error);
      throw error;
    }
  }

  async getBuildingsForCustomer(customerId: string): Promise<any> {
    console.log("Getting customer buildings");

    try {
      const token = this.getAuthToken();

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const url = `${this.url}/api/Building/customer/${customerId}`

      return lastValueFrom(this.http.get(url, { headers: headers }));
    } catch (error) {
      console.error("Error fetching building data:", error);
      throw error;
    }
  }
}
