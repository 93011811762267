import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { roleGuard } from './guards/role.guard';
import { AccessDeniedComponent } from './pages/access-denied/access-denied/access-denied.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { BuildingComponent } from './pages/building/building.component';
export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [roleGuard, MsalGuard],
    data: { role: ['Operator', 'EntuneAdmin', 'BuildingOwner'] },
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [roleGuard, MsalGuard],
    data: { role: ['EntuneAdmin'] },
  },
  {
    path: 'building/:name',
    component: BuildingComponent,
    canActivate: [roleGuard, MsalGuard],
    data: { role:['Operator', 'EntuneAdmin', 'BuildingOwner', 'FacilityManager']  },
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  }
];
