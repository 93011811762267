  <div class="LandingPage">
    <div class="Assistant">
      <app-assistant></app-assistant>
    </div>
    <div class="Dashboard">
      <div class="Navbar">
        <app-navbar [pageTitle]="buildingData.name"></app-navbar>
      </div>
      <div class="DashboardContent">
        <div class="DashboardMetrics" [ngStyle]="{'width': activeTab === 'Overzicht' ? '70%' : '100%'}">
            <div class="Tabs">
                <div class="Tab" (click)="selectTab('Overzicht')" [class.active]="activeTab === 'Overzicht'">Overzicht</div>
                <!-- <div class="Tab" (click)="selectTab('Performance')" [class.active]="activeTab === 'Performance'">Performance</div> -->
                <!-- <div class="Tab" (click)="selectTab('Inzicht')" [class.active]="activeTab === 'Inzicht'">Inzicht</div> -->
                <div class="Tab" (click)="selectTab('Documenten')" [class.active]="activeTab === 'Documenten'">Documenten</div>
                <!-- <div class="Tab" (click)="selectTab('Gacs')" [class.active]="activeTab === 'Gacs'">Gacs</div> -->
              </div>
              <app-building-overview *ngIf="activeTab === 'Overzicht'" ></app-building-overview>
              <app-documents *ngIf="activeTab === 'Documenten'" ></app-documents>
        </div>
        <div class="Notifications" *ngIf="activeTab === 'Overzicht'" >
          <h2>{{buildingData.name}}</h2>
          <img>
          <app-notifications ></app-notifications>
        </div>
      </div>
    </div>
  </div>