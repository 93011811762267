import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService, Notification } from '../../services/notification.service';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="notifications-container">
      <h2>Notifications</h2>
      <div class="notifications-list">
        <div *ngFor="let notification of notifications" class="notification-item">
          <img [src]="getIcon(notification.type)" alt="{{ notification.type }} icon" class="notification-icon" />
          <div class="notification-details">
            <p class="notification-date">{{ notification.date | date:'short' }}</p>
            <p class="notification-building-name">{{ notification.buildingName }}</p>
            <p class="notification-reason">{{ notification.reason }}</p>
          </div>
          <div class="notification-actions">
            <img src="../../assets/delete.png" alt="Delete notification" class="action-icon" (click)="onDeleteNotification(notification.id)" />
            <img src="../../assets/more.png" alt="More info" class="action-icon" (click)="onMoreInfoClick(notification)" />
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .notifications-container {
      padding: 20px;
      overflow: hidden;
      display: flex;
      height: 96%;
      flex-direction: column;
    }

    .notifications-list {
      flex: 1;
      overflow-y: auto;
      margin-top: 10px;
      padding: 10px;
    }

    .notification-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      padding: 20px 0;
    }

    .notification-icon {
      width: 30px;
      height: auto;
      margin-right: 10px;
    }

    .notification-details {
      flex-grow: 1;
    }

    .notification-building-name {
      font-weight: bold;
      margin: 0;
    }

    .notification-date {
      margin: 0;
      font-size: 12px;
      color: grey;
    }

    .notification-reason {
      margin: 0;
      font-size: 12px;
      color: black;
    }

    .notification-actions {
      display: flex;
      flex-direction: column; /* Stack the action icons vertically */
      align-items: center;
      gap: 10px; /* Add some spacing between the icons */
    }

    .action-icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  `]
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[] = [];

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notificationService.getNotifications().subscribe(
      (data) => {
        this.notifications = data;
      },
      (error) => {
        console.error('Error fetching notifications:', error);
      }
    );
  }

  onMoreInfoClick(notification: Notification) {
    console.log('More info clicked for:', notification.reason);
  }

  onDeleteNotification(notificationId: number) {
    console.log('Delete notification with ID:', notificationId);
    this.notifications = this.notifications.filter(n => n.id !== notificationId);
  }

  getIcon(type: string): string {
    switch (type) {
      case "Alert":
        return '../../assets/notification_icons/Alert.png';
      case "Idea":
        return '../../assets/notification_icons/Idea.png';
      case "Information":
        return '../../assets/notification_icons/Information.png';
      default:
        return '../../assets/notification_icons/Information.png';
    }
  }
}
