import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMetricsComponent } from '../dashboard-information/dashboard-metrics.component'; 
import { EnergyConsumptionGraphComponent } from './graphs/cost-savings-graph-component/cost-savings-graph-component.component';
import { CostSavingsGraphComponent } from './graphs/energy-consumption-graph-component/energy-consumption-graph-component.component';

@Component({
  selector: 'app-building-overview',
  standalone: true,
  imports: [CommonModule, DashboardMetricsComponent, EnergyConsumptionGraphComponent, CostSavingsGraphComponent],
  template: `
    <h2>Status & KPI's</h2>
    <br>
    <div class="metrics-grid">
      <!-- Existing metrics -->
      <app-dashboard-metrics
        [name]="'Besparingen'"
        [value]="0.7"
        [unit]="'%'"
        [imgSrc]="'../../assets/kpi_icons/gebouw.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'Kosten besparingen'"
        [value]="1.8"
        [unit]="'K'"
        [imgSrc]="'../../assets/kpi_icons/kosten_besparingen.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'Comfort (PPD)'"
        [value]="6"
        [unit]="'%'"
        [imgSrc]="'../../assets/kpi_icons/comfort.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'CO2 besparingen'"
        [value]="3.4"
        [unit]="'KG/kWh'"
        [imgSrc]="'../../assets/kpi_icons/co2_besparingen.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'Energie verbruik'"
        [value]="-3.7"
        [unit]="'%'"
        [imgSrc]="'../../assets/kpi_icons/energie_verbruik.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        *ngIf="showSixthMetric"
        [name]="'kW piek'"
        [value]="350"
        [unit]="'kW'"
        [imgSrc]="'/assets/kpi_icons/kw_piek.png'">
      </app-dashboard-metrics>

      <div *ngIf="!showSixthMetric"></div>
    </div>
    
    <!-- Graphs Section -->
    <div class="graphs-section">
      <app-energy-consumption-graph></app-energy-consumption-graph>
      <br>
      <app-cost-savings-graph></app-cost-savings-graph>
    </div>
  `,
  styles: [`
    .metrics-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }

    .graphs-section {
      margin-top: 30px; /* Add space between metrics and graphs */
    }

    app-dashboard-metrics {
      /* Adjusting individual metrics inside the grid */
    }

    div[ngIf="!showSixthMetric"] {
      visibility: hidden; /* Keeps an empty space when the sixth metric is hidden */
    }
  `]
})
export class BuildingOverviewComponent {
  showSixthMetric = true;
}
