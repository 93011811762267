import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AssistantComponent } from '../../components/assistant/assistant.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { NotificationsComponent } from '../../components/notifications/notifications.component';
import { DashboardInformationComponent } from '../../components/dashboard-information/dashboard-information.component';
import { BuildingListComponent } from 'src/app/components/dashboard-information/building-list.component';
import { BuildingOverviewComponent } from 'src/app/components/building/building-overzicht.component';
import { DocumentComponent } from 'src/app/components/building/documents/documents.component';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.css'],
  standalone: true,
  imports: [
    AssistantComponent,
    NavbarComponent,
    NotificationsComponent,
    DashboardInformationComponent,
    BuildingListComponent,
    CommonModule,
    BuildingOverviewComponent,
    DocumentComponent
  ]
})
export class BuildingComponent implements OnInit {
  buildingName: string | null = null;
  buildingData: any = null;
  activeTab: string = 'Overzicht';

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Retrieve the building name from the route parameters
    this.buildingName = this.route.snapshot.paramMap.get('title');

    // Check if navigation state is available and set building data
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state?.['data']) {
      this.buildingData = navigation.extras.state['data'];
      console.log('Building data received:', this.buildingData);
    } else {
      console.warn('No data passed to the route.');
    }
  }

  selectTab(tab: string) {
    this.activeTab = tab;
  }
}
