import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  response: any;
  url: string = 'https://coop-aks.swedencentral.cloudapp.azure.com/userservice';
  data: any;

  constructor(private http: HttpClient) {}

  // Helper method to get the authorization token from sessionStorage
  private getAuthToken(): string | null {
    return sessionStorage.getItem('auth_token');
  }

  async getUserData(email: string): Promise<any> {
    console.log("Getting user data for email:", email);

    try {
      const token = this.getAuthToken();

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const url = `${this.url}/api/users/email/${email}`

      return lastValueFrom(this.http.get(url, { headers: headers }));
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }

  async getUserId(id: string): Promise<any> {
    console.log("Getting user data for id:", id);

    try {
      const token = this.getAuthToken();

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const url = `${this.url}/api/users/${id}`

      return lastValueFrom(this.http.get(url, { headers: headers }));
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }

  async getUsersForCustomer(customerId: string): Promise<any> {
    console.log("Getting users for customer:", customerId);

    try {
      const token = this.getAuthToken();

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const response = await this.http
        .get(`${this.url}/api/customers/${customerId}/users`, { headers })
        .toPromise();

      console.log("Users for customer fetched:", response);
      return response;

    } catch (error) {
      console.error("Error fetching users for customer:", error);
      throw error;
    }
  }

}
