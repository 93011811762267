import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-filter',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="filter-container">
      <h2>Filter</h2>
      <div class="filter-list">
        
      </div>
    </div>
  `,
  styles: [`
    .filter-container {
      padding: 20px;
      overflow: hidden;
      display: flex;
      height: 96%;
      flex-direction: column;
    }

    .filter-list {
      flex: 1;
      overflow-y: auto;
      margin-top: 10px;
      padding: 10px;
    }
  `]
})
export class filterComponent {

  constructor() {}
}
