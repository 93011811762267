<div class="LandingPage">
    <div class="Assistant">
      <app-assistant></app-assistant>
    </div>
    <div class="Dashboard">
      <div class="Navbar">
        <app-navbar pageTitle="Access denied"></app-navbar>
      </div>
      <div class="DashboardContent">
        <div class="access-denied">
          <div class="icon">
            <img src="../assets/padlock.png" alt="Lock Icon">
          </div>
          <h1>Access Denied</h1>
          <p>You do not have permission to access this page.</p>
          <p>Please contact EnTune if you believe this is a mistake.</p>
          <button class="redirect-button" (click)="redirectToHome()">Go to Home Page</button>
        </div>
      </div>
    </div>
  </div>
  