import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-metrics',
  standalone: true,
  template: `
    <div class="metric-container">
      <img class="metric-icon" [src]="imgSrc" alt="{{ name }}" />
      <div class="metric-details">
        <p class="metric-name">{{ name }}</p>
        <p class="metric-value">{{ value }} <span class="metric-unit">{{ unit }}</span></p>
      </div>
    </div>
  `,
  styles: [`
    .metric-container {
      display: flex;
      align-items: center;
      padding: 8px;
      margin: 10px 0;
      width: 100%;
      max-width: 300px; /* Optional max width */
    }

    .metric-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .metric-details {
      display: flex;
      flex-direction: column;
    }

    .metric-name {
      font-size: 16px;
      margin: 0;
    }

    .metric-value {
      font-size: 27px;
      font-weight: bold;
      margin: 0;
    }

    .metric-unit {
      font-size: 16px;
    }
  `]
})
export class DashboardMetricsComponent {
  @Input() name!: string;
  @Input() value!: number;
  @Input() unit!: string;
  @Input() imgSrc!: string;
}
