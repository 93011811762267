<div class="LandingPage">
    <div class="Assistant">
      <app-assistant></app-assistant>
    </div>
    <div class="Dashboard">
      <div class="Navbar">
        <app-navbar pageTitle="Gebruikers overzicht"></app-navbar>
      </div>
      <div class="DashboardContent">
        <div class="DashboardMetrics">
            <br>
            <app-user-list></app-user-list>
          </div>
          <div class="Notifications">
            <app-filter></app-filter>
          </div>
      </div>
    </div>
  </div>
  