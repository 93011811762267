<div class="LandingPage">
  <div class="Assistant">
    <app-assistant></app-assistant>
  </div>
  <div class="Dashboard">
    <div class="Navbar">
      <app-navbar></app-navbar>
    </div>
    <div class="DashboardContent">
      <div class="DashboardMetrics">
        <app-dashboard-information></app-dashboard-information>
        <br>
        <app-building-list></app-building-list>
      </div>
      <div class="Notifications">
        <app-notifications></app-notifications>
      </div>
    </div>
  </div>
</div>