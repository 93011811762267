import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GenaiService {
  response: any 
  url: string = 'https://aca-cbe-cop-genai-be-only-dev.greensand-9e30b92a.swedencentral.azurecontainerapps.io/chat/';
  data: any

  constructor(private http: HttpClient) {
  }

  async sendMessageToGenAIAPI(inputMessage: string, session_id: string | null): Promise<any> {
    console.log('Attempting to send message to GenAI API');

    const body = JSON.stringify({ message: inputMessage, session_id: session_id });
    console.log(body)

    const token = sessionStorage.getItem('auth_token');
    if (!token) {
        console.error('No authentication token found in session storage.');
        return Promise.reject('No authentication token found.');
    }

    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    });

    return lastValueFrom(this.http.post(this.url, body, { headers: headers }));
  }
}
