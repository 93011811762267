import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {
  response: any;
  url: string = 'https://coop-aks.swedencentral.cloudapp.azure.com/moduleservice';
  data: any;

  constructor(private http: HttpClient) {}

  private getAuthToken(): string | null {
    return sessionStorage.getItem('auth_token');
  }

  async getModuleBuilding(buildingId: string): Promise<any> {
    console.log("Getting modules for building id", buildingId);

    try {
      const token = this.getAuthToken();

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const url = `${this.url}/api/module/building/${buildingId}`

      return lastValueFrom(this.http.get(url, { headers: headers }));
    } catch (error) {
      console.error("Error fetching modules for building:", error);
      throw error;
    }
  }
}
