import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AssistantComponent } from '../../components/assistant/assistant.component';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { NotificationsComponent } from '../../components/notifications/notifications.component';
import { DashboardInformationComponent } from '../../components/dashboard-information/dashboard-information.component';
import { UserListComponent } from 'src/app/components/user-management/user-list.component';
import { filterComponent } from 'src/app/components/filter/filter.component';

@Component({
  selector: 'app-user-management',
  standalone: true,
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.css',
  imports: [
    AssistantComponent,
    NavbarComponent,
    NotificationsComponent,
    DashboardInformationComponent,
    UserListComponent,
    filterComponent
  ]
})
export class UserManagementComponent {

  constructor(private router: Router) { }

  redirectToHome(): void {
    this.router.navigate(['/dashboard']);
  }
}
