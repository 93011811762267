import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardMetricsComponent } from './dashboard-metrics.component'; // Import the metric component

@Component({
  selector: 'app-dashboard-information',
  standalone: true,
  imports: [CommonModule,DashboardMetricsComponent],
  template: `
    <h2>Status & KPI's</h2>
    <br>
    <div class="metrics-grid">
      <!-- Row 1: Three metrics -->
      <app-dashboard-metrics
        [name]="'Gebouwen'"
        [value]="6"
        [unit]="''"
        [imgSrc]="'../../assets/kpi_icons/gebouw.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'Kosten besparingen'"
        [value]="138.1"
        [unit]="'K'"
        [imgSrc]="'../../assets/kpi_icons/kosten_besparingen.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'Comfort (PPD)'"
        [value]="10"
        [unit]="'%'"
        [imgSrc]="'../../../assets/kpi_icons/comfort.png'">
      </app-dashboard-metrics>

      <!-- Row 2: Two metrics and an optional hidden metric -->
      <app-dashboard-metrics
        [name]="'CO2 besparingen'"
        [value]="4.3"
        [unit]="'KG/kWh'"
        [imgSrc]="'../../assets/kpi_icons/co2_besparingen.png'">
      </app-dashboard-metrics>

      <app-dashboard-metrics
        [name]="'Energie verbruik'"
        [value]="-3.6"
        [unit]="'%'"
        [imgSrc]="'../../assets/kpi_icons/energie_verbruik.png'">
      </app-dashboard-metrics>

      <!-- Sixth metric, conditionally hidden -->
      <app-dashboard-metrics
        *ngIf="showSixthMetric"
        [name]="'Wind Speed'"
        [value]="5"
        [unit]="'km/h'"
        [imgSrc]="'/assets/icons/wind-icon.png'">
      </app-dashboard-metrics>

      <!-- Empty div placeholder to maintain the layout if the sixth metric is hidden -->
      <div *ngIf="!showSixthMetric"></div>
    </div>
  `,
  styles: [`
    .metrics-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Three equal columns */
      gap: 20px;
    }

    app-dashboard-metrics {
      /* Adjusting individual metrics inside the grid */
    }

    div[ngIf="!showSixthMetric"] {
      visibility: hidden; /* Keeps an empty space when the sixth metric is hidden */
    }
  `]
})
export class DashboardInformationComponent {
  showSixthMetric = false;
}
