import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BuildingService } from 'src/app/services/building.service';
import { UserService } from 'src/app/services/user.service';
import { ModuleService } from 'src/app/services/module.service';

@Component({
  selector: 'app-building-list',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <div class="building-list-container">
      <h2>Gebouwen lijst</h2>
      <br>
      <div class="search-container">
        <input class="searchbar" type="text" placeholder="Zoek naar gebouwen op naam..." [(ngModel)]="searchQuery" />
        <button class="map-button" (click)="viewOnMap()">Toon op kaart <img src="../../../assets/map.png" alt="add" class="icon" /></button>
      </div>

      <div class="table-container">
        <table class="building-table" *ngIf="!loading && buildings.length > 0">
          <thead>
            <tr>
              <th>Gebouw naam</th>
              <th>Plaats</th>
              <th>Adres</th>
              <th>Gebouw eigenaar</th>
              <th>Modules</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let building of filterBuildings(); let i = index"
              [ngClass]="{ 'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0 }"
              (click)="goToBuildingDetails(building)"
            >
              <td>{{ building.name }}</td>
              <td>{{ building.address.city }}</td>
              <td>{{ building.address.street }} {{ building.address.houseNumber }}</td>
              <td>{{ building.firstUserName }}</td>
              <td>
                <img
                  src="../../../assets/module_icons/save.png"
                  alt="Save"
                  class="icon"
                  [ngClass]="{ 'grayscale': building.module1Status === 0 }"
                />
                <img
                  src="../../../assets/module_icons/smarttrade.png"
                  alt="Smart Trade"
                  class="icon"
                  [ngClass]="{ 'grayscale': building.module2Status === 0 }"
                />
                <img
                  src="../../../assets/module_icons/coach.png"
                  alt="Coach"
                  class="icon"
                  [ngClass]="{ 'grayscale': building.module3Status === 0 }"
                />
                <img
                  src="../../../assets/module_icons/scan.png"
                  alt="Scan"
                  class="icon"
                  [ngClass]="{ 'grayscale': building.module4Status === 0 }"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="loading" class="loading-spinner">Loading...</div>
        <div *ngIf="!loading && buildings.length === 0" class="no-buildings">Geen gebouwen gevonden</div>
      </div>

      <button class="add-building-btn" (click)="addBuilding()">Voeg een nieuw gebouw toe <img src="../../../assets/add.png" alt="add" class="icon" /></button>
    </div>
  `,
  styles: [
    `
      .loading-spinner {
        text-align: center;
        padding: 20px;
        font-size: 16px;
      }
      .no-buildings {
        text-align: center;
        padding: 20px;
        font-size: 16px;
        color: gray;
      }
       .building-list-container {
      position: relative; /* Keep positioning relative */
      padding-bottom: 60px; /* Add some bottom padding */
    }

    .search-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .search-container input {
      width: 40%;
      padding: 8px;
      margin-right: 10px;
      border: 1px solid white;
      background-color: #f1f1f1;
      border-radius: 4px;
    }

    .map-button {
      padding: 8px 12px;
      background-color: white;
      color: black;
      border: none;
      cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .table-container {
      max-height: 400px;
      overflow-y: auto;
      margin-bottom: 20px;
    }

    .building-table {
      width: 100%;
      border-collapse: collapse;
    }

    .building-table th {
      border-bottom: 2px solid #ddd;
      font-weight: bold;
      text-align: left;
      padding: 8px;
    }

    .building-table td {
      border: none;
      padding: 15px;
    }

    .building-table tbody tr.even-row {
      background-color: white;
      cursor: pointer;
    }

    .building-table tbody tr.odd-row {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    .building-table tbody tr:hover {
      background-color: #f1f1f1; 
    }

    .add-building-btn {
      margin-top: 10px;
      padding: 10px;
      background-color: white;
      color: black;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      z-index: 1000;
      float:right;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .add-building-btn .icon{
    height: 20px;
    width: auto;
    margin-left: 8px;
    }

    .map-button .icon {
    height: 30px;
    width: auto;
    margin-left: 8px;
    filter: grayscale(100%) brightness(10%);
    }

    .icon {
      width: 24px;
      height: auto;
      margin-right: 5px;
    }

    .grayscale {
      filter: grayscale(100%) brightness(140%);
    }
    `
  ]
})
export class BuildingListComponent implements OnInit {
  searchQuery: string = '';
  buildings: any[] = [];
  loading: boolean = true;
  user: any = {};

  constructor(
    private router: Router,
    private buildingService: BuildingService,
    private userService: UserService,
    private moduleService: ModuleService
  ) {}

  ngOnInit() {
    this.loadBuildings();
  }

  async loadBuildings() {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      this.user = JSON.parse(storedUser);

      if (this.user.customerId !== undefined && this.user.userRole !== undefined) {
        if (this.user.userRole === 0) {
          this.buildings = await this.buildingService.getAllBuildings();
        } else {
          this.buildings = await this.buildingService.getBuildingsForCustomer(
            this.user.customerId
          );
        }

        for (const building of this.buildings) {
          const firstUserId = building.userIds[0];
          if (firstUserId) {
            try {
              const user = await this.userService.getUserId(firstUserId);
              building.firstUserName = user.fullName || 'Onbekend';
            } catch (error) {
              building.firstUserName = 'Fout bij laden';
            }
          } else {
            building.firstUserName = 'Geen gebruikers';
          }
        }

        for (const building of this.buildings) {
          const moduleStatuses = await this.moduleService.getModuleBuilding(building.id);
          if (moduleStatuses && moduleStatuses.length === 4) {
            building.module1Status = moduleStatuses[0].status;
            building.module2Status = moduleStatuses[1].status;
            building.module3Status = moduleStatuses[2].status;
            building.module4Status = moduleStatuses[3].status;
          }
        }

        this.loading = false;
      }
    }
  }

  filterBuildings() {
    return this.buildings.filter((building) =>
      building.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  viewOnMap() {
    // Implement map view functionality
  }

  addBuilding() {
    // Implement add building functionality
  }

  goToBuildingDetails(buildingData: any) {
    this.router.navigate(['/building', buildingData.name], { state: { data: buildingData } });
  }
}