import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode, JwtPayload } from "jwt-decode";
import { CommonModule } from '@angular/common';

interface CustomJwtPayload extends JwtPayload {
  jobTitle?: string;
}

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule],
  template: `
    <nav class="navbar">
      <div class="navbar-left">
        <h1>{{ pageTitle }}</h1>
      </div>
      <div class="navbar-right">
        <img 
          *ngIf="canAccessUsers" 
          src="../../../assets/navbar_icons/Users.png" 
          alt="Users" 
          (click)="navigateTo('user-management')" 
          class="icon" 
        />
        <img 
          *ngIf="canAccessGraph" 
          src="../../../assets/navbar_icons/Graph.png" 
          alt="Graph" 
          (click)="navigateTo('graph')" 
          class="icon" 
        />
        <img 
          *ngIf="canAccessBuildings" 
          src="../../../assets/navbar_icons/Buildings.png" 
          alt="Buildings" 
          (click)="navigateTo('/buildingOverview')" 
          class="icon" 
        />
        <img 
          *ngIf="canAccessHome" 
          src="../../../assets/navbar_icons/Home.png" 
          alt="Home" 
          (click)="navigateTo('/')" 
          class="icon" 
        />
      </div>
    </nav>
  `,
  styles: [`
    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 100vw;
      height: 70px;
      padding: 0 20px;
      background-color: #ffffff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      overflow: hidden;
    }
    
    .navbar-left h1 {
      margin: 0;
      font-size: 30px;
    }

    .navbar-right {
      display: flex;
      gap: 20px;
    }

    .navbar-right .icon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: filter 0.2s;
    }

    .navbar-right .icon:hover {
      filter: brightness(0.9) saturate(500%) sepia(100%) hue-rotate(45deg) brightness(70%) contrast(85%);
      transform: scale(1.1);
    }
    
    .navbar-right .icon:active {
      filter: brightness(0.9) saturate(500%) sepia(100%) hue-rotate(45deg) brightness(70%) contrast(85%);
      transform: scale(0.95);
    }
  `]
})
export class NavbarComponent implements OnInit {
  @Input() pageTitle: string = 'Algemeen overzicht'; 

  // Role-based access flags
  canAccessUsers: boolean = false;
  canAccessGraph: boolean = false;
  canAccessBuildings: boolean = false;
  canAccessHome: boolean = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.checkUserRole();
  }

  navigateTo(route: string) {
    this.router.navigate([route]);
  }

  checkUserRole() {
    const token = sessionStorage.getItem('auth_token');

    if (token) {
      const decodedToken = jwtDecode<CustomJwtPayload>(token);
      const userRole = decodedToken.jobTitle;

      this.canAccessUsers = userRole === 'EntuneAdmin';
      this.canAccessGraph = ['EntuneAdmin', 'Operator', 'BuildingOwner'].includes(userRole || '');
      this.canAccessBuildings = ['EntuneAdmin', 'Operator', 'BuildingOwner'].includes(userRole || '');
      this.canAccessHome = ['EntuneAdmin', 'Operator', 'BuildingOwner', 'FacilityManager'].includes(userRole || '');
    }
  }
}
