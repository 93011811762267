import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterOutlet } from '@angular/router';
import { jwtDecode, JwtPayload } from "jwt-decode";
import { UserService } from './services/user.service';
interface CustomJwtPayload extends JwtPayload {
  emails?: string[];
}
import {
  MsalService,
  MsalModule,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  RedirectRequest,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MsalModule,
    RouterOutlet,
    RouterLink,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Co-Operator';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.authService.handleRedirectObservable().subscribe((result) => {
      const token = sessionStorage.getItem('auth_token');
      if (!token) {
        if(!result)
        {
          this.logoutRedirect();
        }
        this.storeToken(result);
      }
      
    });

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          this.loginRedirect();
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.autoLoginIfNotAuthenticated();
      });
  }

  storeToken(result: AuthenticationResult): void {
    sessionStorage.setItem('auth_token', result.idToken);
    this.getUserData(result.idToken);
    console.log('Authentication token stored successfully.');
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): void {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      
    }
  }

  autoLoginIfNotAuthenticated(): void {
    if (this.authService.instance.getAllAccounts().length === 0) {
      this.loginRedirect();
    }
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logoutRedirect(): void{
    sessionStorage.clear();
    localStorage.removeItem('chat_history');
    this.authService.logoutRedirect();
  }

  async getUserData(token:string): Promise<void>{
    const decodedToken = jwtDecode<CustomJwtPayload>(token);
    const email = decodedToken.emails?.[0];
    console.log(email);
    if(!email){
      this.logoutRedirect();
    }else{
      const user = await this.userService.getUserData(email);
      console.log(user);
      if(user){
        sessionStorage.setItem('user', JSON.stringify(user));
      }else{
        this.logoutRedirect();
      }
    }

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
