import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule } from '@angular/forms';
import { GenaiService } from '../../services/genai.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-assistant',
  templateUrl: './assistant.component.html',
  styleUrls: ['./assistant.component.css'],
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatMenuModule, CommonModule, FormsModule],
})
export class AssistantComponent {
  messages: { sender: 'user' | 'ai'; content: string }[] = [];
  userMessage: string = '';
  welcomeMessage: string = `Hi,
  Ik ben de **EnTune Co-Operator Assistent**. Je kunt mij vragen stellen over de volgende onderwerpen:

  - Actieve EnTune modules
  - Systeemmeldingen
  - Energieverbruik

  Stel gerust je vraag, ik help je graag! 
  `;
  sessionId: string | null = null;
  isLoading: boolean = false;
  user: any = {};

  constructor(private genaiService: GenaiService, private authService: AuthService) {
    this.sessionId = sessionStorage.getItem('session_id');
    const storedMessages = sessionStorage.getItem('chat_history');

    if (storedMessages && storedMessages.length >= 1) {
      this.messages = JSON.parse(storedMessages);
    } else {
      this.messages.push({ sender: 'ai', content: this.welcomeMessage });
    }
  }

  ngOnInit(): void {
    this.fetchUserInfo();
  }

  fetchUserInfo(): void {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
  
      const roleMapping: { [key: number]: string } = {
        0: 'EntuneAdmin',
        1: 'Portfolio Operator',
        2: 'Building Owner',
        3: 'Facility Manager'
      };
  
      user.userRole = roleMapping[user.userRole as 0 | 1 | 2 | 3] || 'Unknown Role';
      this.user = user;
    }
  }

  async sendMessage() {
    if (!this.userMessage.trim() || this.isLoading) return;
    console.log(this.sessionId + "sessionid")
    const message = this.userMessage;
    this.userMessage = '';
    this.isLoading = true;

    this.messages.push({ sender: 'user', content: message });

    try {
      const response = await this.genaiService.sendMessageToGenAIAPI(message, this.sessionId);
      sessionStorage.setItem('session_id', response.session_id);
      this.messages.push({ sender: 'ai', content: response.response });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      this.isLoading = false;
      sessionStorage.setItem('chat_history', JSON.stringify(this.messages));
    }
  }

  formatMessageContent(content: string): string {
    if (content.includes('```markdown')) {
      const lines = content.split('\n');
  
      if (lines[0].includes('```markdown')) {
        lines.shift();
      }
      if (lines[lines.length - 1].includes('```')) {
        lines.pop();
      }
  
      content = lines.join('\n');
    }
  
    content = content.replace(/\n/g, '<br>');
    content = content.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  
    return content;
  }
  
  

  clearChat() {
    this.messages = [];
    sessionStorage.removeItem('chat_history');
    sessionStorage.removeItem('session_id');
    this.messages.push({ sender: 'ai', content: this.welcomeMessage });
  }

  logout() {
    sessionStorage.clear();
    localStorage.removeItem('chat_history');
    sessionStorage.removeItem('session_id');
    this.authService.logoutRedirect();
  }
}
