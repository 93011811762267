import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-documents',
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
  <div class="container">
    <div class="document-list-container">
      <h2>Documenten </h2>
      <br>
      <div class="search-container">
        <input class="searchbar" type="text" placeholder="Zoek naar documenten op naam..." [(ngModel)]="searchQuery" />
      </div>

      <div class="table-container">
        <table class="document-table">
          <thead>
            <tr>
              <th>Document naam</th>
              <th>Document type</th>
              <th>Datum</th>
              <th>Geupload door</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let document of filterdocuments(); let i = index" [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}">
              <td>{{ document.name }}</td>
              <td>{{ document.type }}</td>
              <td>{{ document.date }}</td>
              <td>{{ document.upload }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <button class="add-document-btn" (click)="adddocument()">Voeg een nieuw document toe <img src="../../../assets/add.png" alt="add" class="icon"/></button>
    </div>

    <div class="filter">
      <h2>Filter</h2>
      
      <h3>Geupload door</h3>
      <div class="filter-option" *ngFor="let uploader of uploaders">
        <input (click)="toggleFilter('uploader', uploader)" type="radio" name="uploaderFilter" [checked]="selectedUploader === uploader">
        <label  style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
          <div>{{ uploader }}</div> <!-- Name on the left -->
          <div class="count">{{ countUploader(uploader) }}</div> <!-- Count on the right -->
        </label>
      </div>

      <h3>Document type</h3>
      <div class="filter-option" *ngFor="let type of documentTypes">
        <input (click)="toggleFilter('type', type)" type="radio" name="typeFilter" [checked]="selectedType === type">
        <label style="width: 100%; display: flex; justify-content: space-between; align-items: center;">
          <div>{{ type }}</div> <!-- Name on the left -->
          <div class="count">{{ countDocumentType(type) }}</div> <!-- Count on the right -->
        </label>
      </div>
    </div>
  </div>
  `,
  styles: [`
.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
  background-color: white;
}

.document-list-container {
  flex: 2;
  margin-right: 20px;
  background-color: white;
  padding: 20px;
  border-right: 1px solid grey;
  height: 100%;
}

.filter {
  flex: 1;
  padding: 20px;
  background-color: white;
}

.search-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-container input {
  width: 40%;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid white;
  background-color: #ddd;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.document-table {
  width: 100%;
  border-collapse: collapse;
}

.document-table th {
  border-bottom: 2px solid #ddd;
  font-weight: bold;
  text-align: left;
  padding: 8px;
}

.document-table td {
  border: none;
  padding: 15px;
}

.document-table tbody tr.even-row {
  background-color: white;
}

.document-table tbody tr.odd-row {
  background-color: #f9f9f9;
}

.document-table tbody tr:hover {
  background-color: #f1f1f1;
}

.add-document-btn {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  color: black;
  border: 1px solid white;
  cursor: pointer;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-document-btn .icon {
  height: 20px;
  width: auto;
  margin-left: 8px;
}

.icon {
  width: 24px;
  height: auto;
  margin-right: 5px;
}

.filter h2, .filter h3 {
  margin: 10px 0;
  font-weight: bold;
}

.filter-option {
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-option .count {
  color: grey;
}

/* Styling the radio buttons to look like checkboxes */
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #bbb;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  outline: none;
  border-radius: 0; /* Makes it square */
}

input[type="radio"]:checked {
  background-color: green;
  border-color: green;
}

input[type="radio"]:hover {
  border-color: #bbb;
  cursor: pointer;

}
`]
})
export class DocumentComponent {
  constructor(private router: Router) {}

  searchQuery: string = '';
  selectedUploader: string | null = null;
  selectedType: string | null = null;

  documents = [
    { id: 1, name: 'Scan Itility August', type: 'Scan report', date: '01/08/2024', upload: 'Entune BuildingAI'},
    { id: 2, name: 'Scan Itility June', type: 'Scan report', date: '01/07/2024', upload: 'Entune BuildingAI'},
  ];

  uploaders = ['Entune BuildingAI', 'Admin', 'User A'];
  documentTypes = ['Scan report', 'Invoice', 'Contract'];

  filterdocuments() {
    return this.documents.filter(document => 
      document.name.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
      (!this.selectedUploader || document.upload === this.selectedUploader) &&
      (!this.selectedType || document.type === this.selectedType)
    );
  }

  toggleFilter(filterType: string, value: string) {
    if (filterType === 'uploader') {
      this.selectedUploader = this.selectedUploader === value ? null : value;
    } else if (filterType === 'type') {
      this.selectedType = this.selectedType === value ? null : value;
    }
  }

  countUploader(uploader: string): number {
    return this.documents.filter(document => document.upload === uploader).length;
  }

  countDocumentType(type: string): number {
    return this.documents.filter(document => document.type === type).length;
  }

  adddocument() {
    // Add your document addition logic here
  }
}
