import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import {jwtDecode, JwtPayload } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
  jobTitle?: string;
}

@Injectable({
  providedIn: 'root',
})

export class roleGuard implements CanActivate {
  constructor(private authService: MsalService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.authService.instance.getActiveAccount()?.idToken;

    if (token) {
      const decodedToken = jwtDecode<CustomJwtPayload>(token);
      const requiredRoles = route.data['role'];

      if (requiredRoles.includes(decodedToken.jobTitle || '')) {
        return true;
      } else {
        this.router.navigate(['/access-denied']);
        return false;
      }
    } else {
      return false;
    }
  }
}
